export default defineNuxtRouteMiddleware(async () => {
  // skips middleware on server side -- only helps local dev
  if (process.server) return

  const { $auth, $log, isHydrating, payload } = useNuxtApp()

  // skips middleware on first client run (or refresh)
  if (process.client && isHydrating && payload.serverRendered) return

  try {
    const user = await $auth.amplify.currentAuthenticatedUser()

    if (user) {
      return navigateTo('/')
    }
  } catch (e) {
    $log.log('e', e)
  }
})
